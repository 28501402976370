import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Markdown,
  Paragraph,
  Text,
  Image,
  Heading,
  Anchor,
  Button,
} from 'grommet'
import { graphql } from 'gatsby'
import { Java, MailOption } from 'grommet-icons'
import ChatBanner from '../components/ChatBanner'
import Credits from '../components/Credits'
import Layout from '../components/PageLayout'
import Bio from '../components/Bio'
import Slice from '../components/Slice'
import SEO from '../components/SEO'
import { CONTENT, CREDITS, CREDITSLIST } from '../../content/contact'

const ContactPage = ({ data }) => (
  <Layout>
    <Box>
      <SEO
        title="Contact and community at Organic Books"
        description="We're your neighborhood booksellers. Get in touch if you need anything."
        pathname="/contact"
      />
      <Slice
        alignSelf="center"
        alignContent="start"
        margin={{
          bottom: 'xlarge',
        }}
        width="large"
        pad="large"
      >
        <Heading level="1">Contact</Heading>
        <ChatBanner />
        <Text textAlign="center" size="xxlarge" weight="800">
          We&apos;re the Brewer family.
        </Text>

        <Grid
          alignSelf="center"
          fill="horizontal"
          margin={{
            top: 'large',
            bottom: 'small',
          }}
          columns={{
            count: "fit",
            size: '200px',
          }}
          rows={{
            size: '213px',
          }}
          justify="center"
          gap="small"
          style={{zIndex: 0}}
        >
          {data.allFile.edges.map(({ node }) => (
            <Bio name={node.name} pic={node.childImageSharp.fixed} />
          ))}
        </Grid>

        <Markdown
          components={{
            p: {
              component: Paragraph,
              props: {
                size: 'xlarge',
                alignSelf: 'center',
              },
            },
            strong: {
              component: Text,
              props: {
                size: 'xxlarge',
                weight: 800,
              },
            },
            img: {
              component: Image,
              props: {
                fit: 'contain',
              },
            },
            a: {
              component: Button,
              props: {
                align: 'center',
                fill: 'horizontal',
                plain: 'false',
                color: 'accent-3',
                icon: <MailOption color="accent-3" />,
                label: 'organicbooksellers@gmail.com',
              },
            },
          }}
        >
          {CONTENT}
        </Markdown>
      </Slice>
      <Slice
        alignSelf="center"
        alignContent="start"
        margin={{
          bottom: 'xlarge',
        }}
        width="large"
        pad="large"
      >
        <Markdown
          components={{
            p: {
              component: Paragraph,
              props: {
                size: 'xlarge',
                alignSelf: 'center',
              },
            },
            strong: {
              component: Text,
              props: {
                size: 'xxlarge',
                weight: 800,
              },
            },
            img: {
              component: Image,
              props: {
                fit: 'contain',
              },
            },
            a: {
              component: Anchor,
            },
            ul: {
              component: Text,
              props: {
                size: 'large',
              },
            },
          }}
        >
          {CREDITS}
        </Markdown>

        <Credits creditsRows={CREDITSLIST} />
        <Text textAlign="center" margin="large">
          This website made with&nbsp;&nbsp;
          <Java color="brand" />
          &nbsp;&nbsp;by&nbsp;&nbsp;
          Max
        </Text>
      </Slice>
    </Box>
  </Layout>
)

export default ContactPage

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query credQuery {
    allFile(
      filter: { relativeDirectory: { eq: "bios" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fixed(
              height: 140
              duotone: { highlight: "#ffffee", shadow: "#770000", opacity: 20 }
            ) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
