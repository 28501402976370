// Content for the Contact page
export const CONTENT = `
[](mailto://organicbooksellers@gmail.com)


**We love books. We've been writing, reading, designing and editing for over a hundred years.**

Books are more important than ever. In a busy, distracted world, a real book is an oasis. Books change the way we think. They stretch our attention spans, challenge our opinions, transport us to other worlds. People who read books are more curious, more rational, and more kind.

**We're building a place for books and the people who love them.**

A place for community, right here in our beloved Nob Hill.

A place away from the distractions of home and work.

A gateway to other lands, a secret portal to the world of the book people.

**We're on a journey. Come with us.**


`
// Preamble to the list of credits
export const CREDITS = `
  ## Credits

  We couldn't have done this alone. Our community donated books, thoughts and labor through the whole process.

  Some who helped:
`
// Alphabetical list of people 
export const CREDITSLIST = [
    'Rex Allender',
    'Jennifer Barol',
    'Janet  Blair-Wells',
    'Dr. Becky Brahm',
    'Sally Breeder',
    'Anne P. Browne',
    'Bradley Bumgarner-Kirby',
    'Gopa and Jan Campbell',
    'Alfred L.  Clemmons',
    'Stacy Camacho',
    'Lilli and Dayna Diamond',
    'Mary Dudley',
    'Mark Dyke',
    'Hal Fuller',
    'Amanda Fuller Richards',
    'A.J. and Jeanene Gibbs',
    'Jason Gibbs',
    'Marla Hart Clark',
    'Phyllis Hoge Thompson',
    'Judy Jennings',
    'Connie Jones',
    'Kathy Jones Caffrey',
    'Christy Jones Everett',
    'Jerry Lane',
    'Betty Lansdowne',
    'Alison Lemonis',
    'Rev. Brian Merritt',
    'Laurie Mouy',
    'Laura Mudd',
    'Gus Pedrotty',
    'Mark Pedrotty',
    'Charles Piechota',
    'Sarah Reed',
    'Tameron Reynolds',
    'Jay D. Rosenblum',
    'In Memory of Ruth Ellen Simms',
    'Vivia Sparkler',
    'Margaret Tessler',
    'Michael Thomas',
    'Judith Walker Bruesch',
    'Margaret White',
    'Dan and Denise Williams',
    'Linda Wilson',
    'Patricia Wood Smith'
]
