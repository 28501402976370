import React from 'react'
import { Box } from 'grommet'
import { ChatOption } from 'grommet-icons'

const ChatOptionBanner = () => [
  <Box
    direction="row"
    alignSelf="center"
    alignContent="center"
    overflow="hidden"
    margin={{
      top: '-1em',
      bottom: '1em',
    }}
  >
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
    <ChatOption size="small" color="accent-4" alt="" />
  </Box>,
]

export default ChatOptionBanner
